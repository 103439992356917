import { useContext } from "react"
import {
	Box,
	Button,
	Center,
	Divider,
	HStack,
	Stack,
	IconButton,
	Text,
	Tag,
	TagLabel,
} from "@chakra-ui/react"
import { UserContext } from "../Contexts/UserContext.js"
import { SmallCloseIcon } from "@chakra-ui/icons"
import { convertToAMPM, formatPhoneNumber } from "../Utilities"

export const JobRequestOverview = ({
	formData,
	handleChange,
	handleServiceRemoval,
	handleGenericSelectChange,
	previousStep,
	serviceRequests,
	setFormData,
	setEditingIndex,
	onSubmit,
	clientList,
}) => {
	const { userPlus } = useContext(UserContext)
	const handleEditService = async (index) => {
		await setFormData({ ...formData, ...serviceRequests[index] })
		await setEditingIndex(index)
		previousStep()
	}

	const clientEmails = clientList
		.filter((client) => formData.clientIds.includes(client.value))
		.map((client) => client.label)

	return (
		<>
			<Center
				flexDirection="column"
				maxW="629px"
				margin={{ base: "15px", md: "auto" }}
				gap={3}
			>
				<Stack w="100%" gap={2}>
					<Box
						display="flex"
						flexDirection={{ base: "column", md: "row" }}
						justifyContent={{ md: "space-between" }}
						mb="10px"
					>
						<Box>
							<Text fontSize="lg" fontWeight="semibold" my="10px">
								Home Details
							</Text>
							<Box mb="10px">
								<Text fontSize="md" fontWeight="medium" mb="5px">
									Location Address
								</Text>
								<Text>{formData.locationAddress}</Text>
							</Box>
							{userPlus.userType === "agent" && (
								<Box mb="10px">
									<Text fontSize="md" fontWeight="medium" mb="5px">
										Client Emails
									</Text>
									{formData.clientEmailAddress.map((client, index) => {
										return (
											<Tag
												key={index}
												borderRadius="full"
												variant="solid"
												bgColor="red.500"
												mr="5px"
												mb="5px"
											>
												<TagLabel>{client}</TagLabel>
											</Tag>
										)
									})}
									{clientEmails.map((clientEmail, index) => {
										return (
											<Tag
												key={index}
												borderRadius="full"
												variant="solid"
												bgColor="red.500"
												mr="5px"
												mb="5px"
											>
												<TagLabel>{clientEmail}</TagLabel>
											</Tag>
										)
									})}
								</Box>
							)}
						</Box>
					</Box>
					<Divider borderColor="gray.500" />
					<Text fontSize="lg" fontWeight="semibold" my="10px">
						Services
					</Text>
					{serviceRequests.map((serviceRequest, index) => {
						return (
							<Box
								key={index}
								marginBottom="20px"
								w="100%"
								display="flex"
								flexDirection={{ base: "column", md: "row" }}
								justifyContent={{ md: "space-between" }}
							>
								<Box>
									<Text fontSize="md" fontWeight="semibold" mb="5px">
										{serviceRequest.serviceType}
									</Text>
									<Box>
										{serviceRequest.vendorIds.map((vendor, index) => {
											return (
												<Tag
													key={index}
													borderRadius="full"
													variant="solid"
													colorScheme="gray"
													mr="5px"
													mb="5px"
												>
													<TagLabel>{vendor.label}</TagLabel>
												</Tag>
											)
										})}
									</Box>
									<Box>
										{serviceRequest.vendorInviteEmails &&
										serviceRequest.vendorInviteEmails.length > 0
											? serviceRequest.vendorInviteEmails.map(
													(vendor, index) => {
														return (
															<Tag
																key={index}
																borderRadius="full"
																borderColor="gray.500"
																border="1px"
																variant="solid"
																bgColor="white"
																color="gray.500"
																mr="5px"
																mb="5px"
															>
																<TagLabel>{vendor}</TagLabel>
															</Tag>
														)
													}
											  )
											: null}
										{serviceRequest.vendorInvitePhoneNumbers &&
										serviceRequest.vendorInvitePhoneNumbers.length > 0
											? serviceRequest.vendorInvitePhoneNumbers.map(
													(vendor, index) => {
														return (
															<Tag
																key={index}
																borderRadius="full"
																borderColor="gray.500"
																border="1px"
																variant="solid"
																bgColor="white"
																color="gray.500"
																mr="5px"
																mb="5px"
															>
																<TagLabel>
																	{formatPhoneNumber(vendor.phoneNumber)}
																</TagLabel>
															</Tag>
														)
													}
											  )
											: null}
									</Box>
									<Text fontSize="md">
										{serviceRequest.servicePreferredDates.map((date, index) => {
											return (
												<HStack spacing="25px" key={index}>
													<Text>{date.date.toLocaleDateString("en-US")} </Text>
													<Text>
														{convertToAMPM(date.startTime)} -{" "}
														{convertToAMPM(date.endTime)}
													</Text>
												</HStack>
											)
										})}
									</Text>
								</Box>
								<Box
									display="flex"
									flexDirection={{ base: "column", md: "row" }}
								>
									<IconButton
										display={{ base: "none", md: "flex" }}
										size="sm"
										aria-label="Delete Service Request"
										icon={<SmallCloseIcon />}
										onClick={() => handleServiceRemoval(index)}
										variant="ghost"
										colorScheme="red"
									/>
									<Button
										display={{ base: "flex", md: "none" }}
										w="100%"
										mt="10px"
										onClick={() => handleServiceRemoval(index)}
									>
										{" "}
										Remove Service Request{" "}
									</Button>
									<Button
										size={{ base: "md", md: "sm" }}
										w={{ base: "100%", md: "" }}
										mt={{ base: "10px", md: "0px" }}
										bgColor="blue.500"
										color="white"
										onClick={() => handleEditService(index)}
									>
										Edit
									</Button>
								</Box>
							</Box>
						)
					})}
				</Stack>
				<Button
					onClick={(e) => onSubmit(e, "overview")}
					w="100%"
					bgColor="red.500"
					color="white"
					mt="20px"
				>
					Submit Job Requests
				</Button>
				<Button onClick={previousStep} w="100%" mt="10px" mb="40px">
					Add Another Service
				</Button>
			</Center>
		</>
	)
}
