import { useState } from "react"
import {
	Button,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	HStack,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalFooter,
	ModalContent,
	ModalCloseButton,
	ModalBody,
} from "@chakra-ui/react"
import DatePicker from "react-datepicker"
import "../date-picker.css"

export const JobRequestScheduleModal = ({
	handleScheduling,
	jobRequest,
	jobRequestResponse,
	isOpen,
	onClose,
}) => {
	const [dateTimes, setDateTimes] = useState({
		date: null,
		startTime: "",
		endTime: "",
	})
	const [servicePreferredDateError, setServicePreferredDateError] =
		useState(false)
	const handleDateChange = (date) => {
		setDateTimes({ ...dateTimes, date })
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setServicePreferredDateError(false)
		setDateTimes({ ...dateTimes, [name]: value })
	}

	const onSubmit = () => {
		if (
			(dateTimes.date !== null &&
				(dateTimes.startTime === "" || dateTimes.endTime === "")) ||
			(dateTimes.startTime !== "" &&
				(dateTimes.date === null || dateTimes.endTime === "")) ||
			(dateTimes.endTime !== "" &&
				(dateTimes.date === null || dateTimes.startTime === ""))
		) {
			setServicePreferredDateError(true)
		} else {
			handleScheduling(dateTimes, jobRequestResponse)
			setDateTimes({ date: null, startTime: "", endTime: "" })
			onClose()
		}
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="3xl">
			<ModalOverlay />
			<ModalContent mx={{ base: "10px", md: "0px" }}>
				<ModalHeader>Schedule {jobRequest.serviceType}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<p>
						Select a date and time and we will connect you and{" "}
						{jobRequestResponse.vendorName
							? jobRequestResponse.vendorName
							: jobRequestResponse.vendorEmail}{" "}
						on an email to coordinate your {jobRequest.serviceType} job for{" "}
						{jobRequest.locationAddress}.{" "}
					</p>
					<FormControl isInvalid={servicePreferredDateError} mt="15px">
						<FormLabel>Preferred Schedule Date</FormLabel>
						<DatePicker
							w="100%"
							h="40px"
							border-radius="md"
							border={
								servicePreferredDateError
									? "1px solid red"
									: "1px solid #e2e2ea"
							}
							selected={dateTimes.date}
							onChange={handleDateChange}
							placeholderText="Select date"
							dateFormat="MM/dd/yyyy"
						/>
						{servicePreferredDateError && (
							<FormErrorMessage>Preferred date required.</FormErrorMessage>
						)}
					</FormControl>
					<HStack my="15px" gap={2} w="100%">
						<FormControl isInvalid={servicePreferredDateError}>
							<Input
								border={
									servicePreferredDateError
										? "1px solid red"
										: "1px solid #e2e2ea"
								}
								type="time"
								name="startTime"
								value={dateTimes.startTime}
								variant="jobRequest"
								onChange={handleInputChange}
							/>
							{servicePreferredDateError ? (
								<FormErrorMessage>
									Preferred start time is required.
								</FormErrorMessage>
							) : null}
						</FormControl>

						<FormControl isInvalid={servicePreferredDateError}>
							<Input
								border={
									servicePreferredDateError
										? "1px solid red"
										: "1px solid #e2e2ea"
								}
								type="time"
								name="endTime"
								value={dateTimes.endTime}
								onChange={handleInputChange}
								variant="jobRequest"
							/>
							{servicePreferredDateError && (
								<FormErrorMessage>
									Preferred end time required.
								</FormErrorMessage>
							)}
						</FormControl>
					</HStack>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="blue"
						mr={3}
						onClick={onSubmit}
						w="100%"
						bg="red.500"
						color="white"
						_hover={{ bgColor: "red.500" }}
					>
						Send Schedule Request
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
