import {
	Button,
	Container,
	Heading,
	Image,
	Link,
	Modal,
	ModalOverlay,
	ModalFooter,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	Text,
} from "@chakra-ui/react"
import { formatPhoneNumber } from "../Utilities"

export const ContactAgentModal = ({ agentInfo, onClose, isOpen }) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size="3xl">
			<ModalOverlay />
			<ModalContent mx={{ base: "10px", md: "0px" }}>
				<ModalCloseButton />
				<ModalBody display="flex" flexDirection="column" alignItems="center">
					{agentInfo.photo && (
						<Image
							mt="30px"
							src={agentInfo.photo}
							alt="agent"
							borderRadius="full"
							w="300px"
							h="300px"
						/>
					)}

					<Container
						display="flex"
						flexDirection="column"
						alignItems="center"
						mt="30px"
					>
						<Heading size="md" fontWeight="bold" pb="5px">
							<strong>{agentInfo?.name}</strong>
						</Heading>
						<Text>{agentInfo?.email}</Text>
						<Text>{formatPhoneNumber(agentInfo?.phoneNumber)}</Text>
					</Container>
				</ModalBody>
				<ModalFooter>
					<Button
						as={Link}
						mr={3}
						href={`mailto:${agentInfo?.email}?subject=Quiplo Inquiry`}
						w="100%"
						bg="red.500"
						mb="30px"
						color="white"
						textDecoration="none"
						_hover={{ bgColor: "red.500" }}
					>
						Email {agentInfo?.name.split(" ")[0]}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
