import React, { useEffect, useState, useContext } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate, useParams } from "react-router-dom"
import { collection, getDocs, query, where } from "firebase/firestore"
import { auth, registerWithEmailAndPassword, db } from "../firebase"
import {
	Box,
	Card,
	Container,
	Center,
	Divider,
	Flex,
	Heading,
	Image,
	Link,
	Text,
	Stack,
	Spinner,
} from "@chakra-ui/react"
import { UserContext } from "../Contexts/UserContext.js"
import { RegistrationFirstStep } from "../Components/RegistrationFirstStep"
import { RegistrationSecondStep } from "../Components/RegistrationSecondStep"
import { VendorRegistrationSecondStep } from "../Components/VendorRegistrationSecondStep"
import { LeadsCard } from "../Components/LeadsCard"
import { VendorCard } from "../Components/VendorCard"
import { ReferralCard } from "../Components/ReferralCard"
import { AgentProfileInfo } from "../Components/AgentProfileInfo"
import { teamIdDefaultIds } from "../Options/DefaultTeamIds.js"
import { formatPhoneNumber } from "../Utilities"

export const Register = () => {
	const { id } = useParams()
	const navigate = useNavigate()
	const [user, loading] = useAuthState(auth)
	const { userPlus } = useContext(UserContext)
	const [step, setStep] = useState(1)
	const [formData, setFormData] = useState({
		email: null,
		password: null,
		userType: null,
		name: null,
		phoneNumber: null,
	})
	const [agentInfo, setAgentInfo] = useState(null)

	useEffect(() => {
		if (id && id.length > 10) {
			const getAgentInfo = async () => {
				const q = query(
					collection(db, "publicAgentProfile"),
					where("id", "==", id)
				)
				const docs = await getDocs(q)
				if (docs.docs.length === 0) {
					setAgentInfo(null)
				} else {
					setAgentInfo(docs.docs[0].data())
				}
			}
			getAgentInfo()
		}
	}, [id])

	const register = () => {
		registerWithEmailAndPassword(formData, id)
	}

	const nextStep = async () => {
		setStep(2)
	}

	const handleChange = (name, e) => {
		setFormData({ ...formData, [name]: e })
	}

	useEffect(() => {
		if (loading) return
		if (
			user &&
			userPlus !== "pending" &&
			userPlus !== null &&
			userPlus.userType &&
			userPlus.userType !== null
		) {
			if (userPlus.userType === "vendor") {
				navigate("/job-requests")
			} else {
				navigate("/vendors")
			}
		}
	}, [user, loading, userPlus])

	if (loading) {
		return (
			<Center mt="50px">
				<Stack>
					<Spinner m="auto" />
				</Stack>
			</Center>
		)
	}

	const team = teamIdDefaultIds.find((team) => team.teamId === id) || null

	return (
		<>
			<Box
				ml={{ base: "10px", sm: "40px", md: "96px" }}
				my={{ base: "15px", md: "40px" }}
				display="flex"
				flexGrow={1}
				alignItems="flex-end"
				width="100%"
			>
				<Link to="/">
					<Flex>
						<Image
							src={
								team
									? team.logo_path
									: process.env.PUBLIC_URL + "/assets/logo_with_name.svg"
							}
						/>{" "}
						{team ? (
							<>
								<Divider
									orientation="vertical"
									borderColor="coolGray.400"
									borderWidth={1}
									height="80px"
									ml="20px"
									mr="20px"
									alignSelf="center"
								/>
								<Image src={"/assets/logo_with_name.svg"} />
							</>
						) : null}
					</Flex>
				</Link>
			</Box>
			<Box display={{ md: "flex" }} alignItems={{ md: "stretch" }}>
				<Box flex={1}>
					{step === 1 && (
						<Heading
							as="h2"
							ml={{ base: "10px", sm: "40px", md: "96px" }}
							mr={{ base: "40px" }}
							fontSize="24px"
							mt={{ base: "15px", sm: "30px" }}
							fontWeight="600"
						>
							{" "}
							{agentInfo ? (
								"Get access to my preferred vendors for your home services needs!"
							) : (
								<>
									The home services exchange for real estate agents, vendors,
									and homeowners!
								</>
							)}
						</Heading>
					)}
					{step === 2 && formData.userType === "client" && (
						<Heading
							as="h2"
							ml={{ base: "10px", sm: "40px", md: "96px" }}
							mr={{ base: "40px" }}
							fontSize="24px"
							mt={{ base: "15px", sm: "30px" }}
							fontWeight="600"
						>
							{" "}
							Get access to your real estate agent's preferred vendors for your
							home services needs!
						</Heading>
					)}
					{step === 2 && formData.userType !== "client" && (
						<Heading
							as="h2"
							ml={{ base: "10px", sm: "40px", md: "96px" }}
							mr={{ base: "40px" }}
							fontSize="24px"
							mt={{ base: "15px", sm: "30px" }}
							fontWeight="600"
						>
							{" "}
							Sign up for free to grow your business with Quiplo!
						</Heading>
					)}
					<Box
						ml={{ base: "10px", sm: " 40px", md: "96px" }}
						mr={{ base: "10px", sm: " 40px", md: "96px" }}
						my={{ base: "15px", md: "30px" }}
					>
						{agentInfo && (
							<Card
								my="20px"
								display={["flex", "flex", "none", "none"]}
								flexDirection="row"
								maxW="xl"
								p="20px"
							>
								{agentInfo.photo && (
									<Image
										src={agentInfo.photo}
										alt="agent"
										borderRadius="full"
										w="65px"
										h="65px"
									/>
								)}

								<Container>
									<Heading size="md" fontWeight="bold" pb="5px">
										<strong>{agentInfo.name}</strong>
									</Heading>
									<Text>{formatPhoneNumber(agentInfo.phoneNumber)}</Text>
									<Link
										mt={2}
										color="blue.500"
										href={`mailto:${agentInfo.email}?subject=Quiplo Inquiry`}
									>
										Email {agentInfo.name.split(" ")[0]}
									</Link>
								</Container>
							</Card>
						)}
						{step === 1 && (
							<RegistrationFirstStep
								formData={formData}
								nextStep={nextStep}
								handleChange={handleChange}
								id={id}
							/>
						)}
						{step === 2 && formData.userType !== "vendor" && (
							<RegistrationSecondStep
								formData={formData}
								setFormData={setFormData}
								handleChange={handleChange}
								register={register}
								id={id}
							/>
						)}
						{step === 2 && formData.userType === "vendor" && (
							<VendorRegistrationSecondStep
								formData={formData}
								setFormData={setFormData}
								handleChange={handleChange}
								register={register}
								id={id}
							/>
						)}
					</Box>
				</Box>
				<Box
					flex={1}
					style={{
						backgroundImage: `linear-gradient(to bottom, #fafafa 1%, rgba(245, 246, 252, 0.01)), url(${process.env.PUBLIC_URL}/assets/stock_house_login.png)`,
						backgroundSize: "cover",
						height: "100vh",
					}}
					pt={{ base: "20px", md: "0px" }}
				>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						pr="10px"
						pl="10px"
					>
						{agentInfo && (
							<>
								<Card
									mt="20px"
									display={["none", "none", "flex", "flex"]}
									flexDirection="row"
									maxW="xl"
									minW="xl"
									p="20px"
								>
									{agentInfo.photo && (
										<Image
											src={agentInfo.photo}
											alt="agent"
											borderRadius="full"
											w="65px"
											h="65px"
										/>
									)}
									<Container>
										<Heading size="md" fontWeight="bold" pb="5px">
											<strong>{agentInfo.name}</strong>
										</Heading>
										<Text>{formatPhoneNumber(agentInfo.phoneNumber)}</Text>
										<Link
											mt={2}
											color="blue.500"
											href={`mailto:${agentInfo.email}?subject=Quiplo Inquiry`}
										>
											Email {agentInfo.name.split(" ")[0]}
										</Link>
									</Container>
								</Card>
								<AgentProfileInfo agentInfo={agentInfo} />
							</>
						)}
						{!agentInfo && (
							<>
								<VendorCard />
								<LeadsCard />
								<ReferralCard />
							</>
						)}
					</Box>
				</Box>
			</Box>
		</>
	)
}
