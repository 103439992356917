import { useState, useContext } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import {
	addDoc,
	collection,
	query,
	doc,
	where,
	getDocs,
	getDoc,
	updateDoc,
} from "firebase/firestore"
import { Box, Center, Heading, Text, Stack, Spinner } from "@chakra-ui/react"
import { VendorInviteForm } from "../Components/VendorInviteForm"
import { InviteTypeForm } from "../Components/InviteTypeForm"
import { VendorInviteOverview } from "../Components/VendorInviteOverview"
import { db, logUserEvent } from "../firebase"
import { analytic_events } from "../analytics"
import { UserContext } from "../Contexts/UserContext"
import config from "../config.js"

export const Invite = () => {
	const { userPlus } = useContext(UserContext)
	const [step, setStep] = useState(1)
	const [value, setValue] = useState(
		userPlus?.userType === "vendor" ? "agent" : ""
	)
	const [editingIndex, setEditingIndex] = useState(null)
	const [inviteEmails, setInviteEmails] = useState([])
	const [vendorData, setVendorData] = useState({
		name: "",
		companyEmail: "",
		phoneNumber: "",
		serviceTypes: [],
		serviceStates: [],
		serviceCities: [],
	})
	const [vendorList, setVendorList] = useState([])
	const navigate = useNavigate()
	const [pageLoading, setPageLoading] = useState(false)
	const [tcTeam, setTcTeam] = useState(null)
	const [tcAgent, setTcAgent] = useState(null)
	const [teamMembersSelected, setTeamMembersSelected] = useState(null)

	const handleClientUser = async (inviteEmail) => {
		const invitedQuery = query(
			collection(db, "users"),
			where("email", "==", inviteEmail.toLowerCase())
		)
		const doc1 = await getDocs(invitedQuery)
		const userExists = doc1.docs.length > 0

		if (userExists) {
			const existingUser = doc1.docs[0].data()
			const userDocRef = doc1.docs[0].ref

			if (
				(userPlus.userType === "agent" || userPlus.userType === "TC") &&
				existingUser.userType === "agent"
			) {
				return
			}

			const existingUserId = existingUser.id

			if (userPlus.userType === "vendor") {
				const relationshipQuery = query(
					collection(db, "agentVendor"),
					where("vendorId", "==", userPlus.id),
					where("agentId", "==", doc1.docs[0].data().id)
				)

				const association = await getDocs(relationshipQuery)

				if (association.docs.length === 0) {
					const docData = {
						id: crypto.randomUUID(),
						agentId: doc1.docs[0].data().id,
						vendorId: userPlus.id,
					}

					await addDoc(collection(db, "agentVendor"), docData)
				}
				return
			}
			const relationshipQuery = query(
				collection(db, "agentClient"),
				where("clientId", "==", existingUserId)
			)

			const association = await getDocs(relationshipQuery)
			const isUserAlreadyAssociated = association.docs.length > 0
			const assocDocRef = association.docs[0].ref

			if (
				isUserAlreadyAssociated &&
				association.docs[0].agentId !== userPlus.id &&
				userPlus.userType === "agent"
			) {
				updateDoc(assocDocRef, {
					agentId: userPlus.id,
					clientId: existingUserId,
					teamId: userPlus.teamId ? userPlus.teamId : null,
				})
				updateDoc(userDocRef, {
					invitedBy: userPlus.id,
				})
			}
			if (!isUserAlreadyAssociated) {
				const docData = {
					id: crypto.randomUUID(),
					agentId: userPlus.userType === "TC" ? tcAgent : userPlus.id,
					clientId: existingUserId,
					teamId:
						userPlus.userType === "TC"
							? tcTeam
							: userPlus.teamId
							? userPlus.teamId
							: null,
				}

				await addDoc(collection(db, "agentClient"), docData)
			}
		} else {
			const userData = {
				id: crypto.randomUUID(),
				email: inviteEmail.toLowerCase(),
				invitedBy: userPlus.userType === "TC" ? tcAgent : userPlus.id,
				userType: value,
				teamId:
					userPlus.userType === "TC"
						? tcTeam
						: userPlus.teamId
						? userPlus.teamId
						: null,
				_createdAt: new Date(),
			}

			const userDoc = await addDoc(collection(db, "users"), userData)
			const newUser = (await getDoc(doc(db, "users", userDoc.id))).data()
			const userId = newUser.id

			if (
				(userPlus.userType === "agent" || userPlus.userType === "TC") &&
				newUser.userType === "agent"
			) {
				return
			}

			if (userPlus.userType === "vendor") {
				const docData = {
					id: crypto.randomUUID(),
					agentId: userId,
					vendorId: userPlus.id,
				}

				await addDoc(collection(db, "agentVendor"), docData)
				await logUserEvent(analytic_events.INVITE_SENT, userPlus.id, {
					email: inviteEmail,
					inviteType: "agent",
					invitedBy: userPlus.id,
				})
				return
			}

			const docData = {
				id: crypto.randomUUID(),
				agentId: userPlus.userType === "TC" ? tcAgent : userPlus.id,
				clientId: userId,
				teamId:
					userPlus.userType === "TC"
						? tcTeam
						: userPlus.teamId
						? userPlus.teamId
						: null,
			}

			await addDoc(collection(db, "agentClient"), docData)
			await logUserEvent(analytic_events.INVITE_SENT, userPlus.id, {
				email: inviteEmail,
				inviteType: "client",
				invitedBy: userPlus.id,
			})
		}
	}

	const onSubmit = async () => {
		try {
			setPageLoading(true)
			await Promise.all(
				inviteEmails.map(async (inviteEmail) => {
					await handleClientUser(inviteEmail)
				})
			)

			if (value === "client") {
				if (userPlus.userType === "agent") {
					sendClientInviteEmail(
						userPlus.name,
						inviteEmails,
						userPlus.email,
						userPlus.id
					)
				} else {
					const q = query(collection(db, "users"), where("id", "==", tcAgent))
					const doc = await getDocs(q)
					const user = doc.docs[0].data()
					sendClientInviteEmail(user.name, inviteEmails, user.email, tcAgent)
				}
			} else {
				sendInviteEmail(userPlus.name, inviteEmails, userPlus.email)
			}

			navigate("/job-requests")
			setPageLoading(false)
		} catch (error) {
			console.error("Error adding user and associating:", error)
		}
	}

	const nextStep = () => {
		setStep(step + 1)
	}

	const previousStep = () => {
		setStep(step - 1)
	}

	const sendInviteEmail = async (userName, inviteEmails, userEmail) => {
		if (Array.isArray(inviteEmails)) {
			await Promise.all(
				inviteEmails.map(async (inviteeEmail) => {
					try {
						await axios.post(`${config.baseURL}/api/invite-to-quiplo`, {
							inviteeEmail,
							userEmail,
							userName,
						})
					} catch (error) {
						console.error("Error sending email:", error)
					}
				})
			)
		} else {
			await axios.post(`${config.baseURL}/api/invite-to-quiplo`, {
				inviteeEmail: inviteEmails,
				userEmail,
				userName,
			})
		}
	}

	const sendClientInviteEmail = async (
		userName,
		inviteEmails,
		userEmail,
		userId
	) => {
		if (Array.isArray(inviteEmails)) {
			await Promise.all(
				inviteEmails.map(async (inviteeEmail) => {
					try {
						await axios.post(`${config.baseURL}/api/invite-client-to-quiplo`, {
							inviteeEmail,
							userEmail,
							userName,
							userId,
						})
					} catch (error) {
						console.error("Error sending email:", error)
					}
				})
			)
		} else {
			await axios.post(`${config.baseURL}/api/invite-client-to-quiplo`, {
				inviteeEmail: inviteEmails,
				userEmail,
				userName,
				userId,
			})
		}
	}

	if (pageLoading) {
		return (
			<Center mt="50px">
				<Stack>
					<Spinner m="auto" />
				</Stack>
			</Center>
		)
	}

	return (
		<>
			<Box
				ml={{ base: "15px", lg: "93px" }}
				mr={{ base: "15px", lg: "93px" }}
				mt="30px"
			>
				<Heading size="lg" fontWeight="bold">
					Invite others to Quiplo
				</Heading>
				<Text>
					Invite your preferred network of vendors, colleagues and clients.{" "}
				</Text>
				<Center
					maxW="612px"
					display="flex"
					flexDirection="column"
					margin="auto"
				>
					{step === 1 && (
						<InviteTypeForm
							nextStep={nextStep}
							onSubmit={onSubmit}
							value={value}
							inviteEmails={inviteEmails}
							setInviteEmails={setInviteEmails}
							tcTeam={tcTeam}
							tcAgent={tcAgent}
							setTcAgent={setTcAgent}
							setTcTeam={setTcTeam}
							setValue={setValue}
						/>
					)}
					{step === 2 && (
						<VendorInviteForm
							vendorList={vendorList}
							setVendorList={setVendorList}
							sendInviteEmail={sendInviteEmail}
							previousStep={previousStep}
							nextStep={nextStep}
							setEditingIndex={setEditingIndex}
							editingIndex={editingIndex}
							setTcTeam={setTcTeam}
							tcTeam={tcTeam}
							tcAgent={tcAgent}
							setTcAgent={setTcAgent}
							setTeamMembersSelected={setTeamMembersSelected}
							teamMembersSelected={teamMembersSelected}
							vendorData={vendorData}
							setVendorData={setVendorData}
						/>
					)}
					{step === 3 && (
						<VendorInviteOverview
							vendorList={vendorList}
							setVendorList={setVendorList}
							setVendorData={setVendorData}
							setTcAgent={setTcAgent}
							tcAgent={tcAgent}
							tcTeam={tcTeam}
							setTcTeam={setTcTeam}
							sendInviteEmail={sendInviteEmail}
							setTeamMembersSelected={setTeamMembersSelected}
							teamMembersSelected={teamMembersSelected}
							previousStep={previousStep}
							setEditingIndex={setEditingIndex}
						/>
					)}
				</Center>
			</Box>
		</>
	)
}
