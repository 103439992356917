import { useState, useContext, useEffect } from "react"
import axios from "axios"
import { useNavigate, useLocation, NavLink } from "react-router-dom"
import config from "../../config.js"
import {
	Box,
	Center,
	HStack,
	Heading,
	IconButton,
	Link,
	Spinner,
	Stack,
	Text,
} from "@chakra-ui/react"
import { ArrowBackIcon } from "@chakra-ui/icons"
import {
	addDoc,
	collection,
	query,
	where,
	getDocs,
	getDoc,
	doc,
	updateDoc,
	increment,
} from "firebase/firestore"
import { JobRequestGeneralInformation } from "../../Components/JobRequestGeneralInformation"
import { JobRequestServiceDetails } from "../../Components/JobRequestServiceDetails"
import { JobRequestOverview } from "../../Components/JobRequestOverview"
import { db, logUserEvent } from "../../firebase"
import { analytic_events } from "../../analytics.js"
import { UserContext } from "../../Contexts/UserContext.js"

export const CreateJobRequest = () => {
	const { userPlus } = useContext(UserContext)
	const navigate = useNavigate()
	const location = useLocation()
	const selectedVendors = location.state?.selectedVendors

	const [step, setStep] = useState(1)
	const [formError, setFormError] = useState(false)
	const [formData, setFormData] = useState({
		locationAddress: null,
		estimatedSquareFeet: null,
		clientIds: [],
		clientEmailAddress: [],
		areUtilitiesOn: null,
		isPropertyOccupied: null,
		isDealUnderContract: null,
		inspectionPeriod: null,
		closingDate: null,
		serviceType: [],
		servicePreferredDates: [],
		timePreference: null,
		vendorIds: selectedVendors ? selectedVendors : [],
		vendorInviteEmails: [],
		vendorInvitePhoneNumbers: [],
		jobRequestDetails: null,
		teamMemberId: null,
	})
	const [dateTimes, setDateTimes] = useState({
		date: null,
		startTime: "",
		endTime: "",
	})
	const [serviceRequests, setServiceRequests] = useState([])
	const [editingIndex, setEditingIndex] = useState(null)
	const [agentEmail, setAgentEmail] = useState("")
	const [clientList, setClientList] = useState([])
	const [submitting, setSubmitting] = useState(false)

	useEffect(() => {
		if (userPlus === "pending") {
			return
		}
		const getAgentInformation = async () => {
			const q1 = query(
				collection(db, "agentClient"),
				where("clientId", "==", userPlus.id)
			)
			const doc = await getDocs(q1)
			const agentId = doc.docs[0].data().agentId
			const q2 = query(collection(db, "users"), where("id", "==", agentId))
			const agentDoc = await getDocs(q2)
			const agentEmail = agentDoc.docs[0].data().email
			setAgentEmail(agentEmail)
		}
		if (userPlus.userType === "client") {
			getAgentInformation()
		}
	}, [userPlus])

	const nextStep = () => {
		setStep(step + 1)
	}

	const previousStep = () => {
		setStep(step - 1)
	}

	const handleChange = (input, e) => {
		setFormData({ ...formData, [input]: e })
	}

	const handleServiceTimeRemoval = (index) => {
		const newServicePreferredDates = [...formData.servicePreferredDates]
		newServicePreferredDates.splice(index, 1)
		setFormData({
			...formData,
			servicePreferredDates: newServicePreferredDates,
		})
	}

	const handleVendorPhoneInviteRemoval = (index) => {
		const newVendorPhone = [...formData.vendorInvitePhoneNumbers]
		newVendorPhone.splice(index, 1)
		setFormData({
			...formData,
			vendorInvitePhoneNumbers: newVendorPhone,
		})
	}

	const handleServiceRemoval = (index) => {
		const newServiceRequests = [...serviceRequests]
		newServiceRequests.splice(index, 1)
		setServiceRequests(newServiceRequests)
	}

	const handleGenericSelectChange = (selectedOptions, fieldName) => {
		if (Array.isArray(selectedOptions)) {
			setFormData({
				...formData,
				[fieldName]: selectedOptions.map((option) => option.value),
			})
		} else {
			setFormData({
				...formData,
				[fieldName]: selectedOptions.value,
			})
		}
	}

	const handleAddServiceRequest = async (e) => {
		e.preventDefault()
		if ((await verifyJobRequestService()) === false) {
			return "error"
		}
		if (editingIndex !== null) {
			const newServiceRequests = [...serviceRequests]
			newServiceRequests[editingIndex] = {
				...formData,
				servicePreferredDates: [
					...formData.servicePreferredDates,
					...(dateTimes.date !== null &&
					dateTimes.startTime !== "" &&
					dateTimes.endTime !== ""
						? [dateTimes]
						: []),
				],
			}
			setServiceRequests(newServiceRequests)
			setFormData({
				...formData,
				serviceType: [],
				servicePreferredDates: [],
				vendorInvitePhoneNumbers: [],
				vendorIds: [],
				vendorInviteEmails: [],
				timePreference: null,
				jobRequestDetails: "",
			})
			setDateTimes({ date: null, startTime: "", endTime: "" })
			setEditingIndex(null)
			nextStep()
		} else {
			setServiceRequests([
				...serviceRequests,
				{
					...formData,
					servicePreferredDates: [
						...formData.servicePreferredDates,
						...(dateTimes.date !== null &&
						dateTimes.startTime !== "" &&
						dateTimes.endTime !== ""
							? [dateTimes]
							: []),
					],
				},
			])
			setFormData({
				...formData,
				serviceType: [],
				servicePreferredDates: [],
				vendorInvitePhoneNumbers: [],
				vendorIds: [],
				timePreference: null,
				vendorInviteEmails: [],
				jobRequestDetails: "",
			})
			setDateTimes({ date: null, startTime: "", endTime: "" })
		}
	}

	const verifyJobRequestGeneralInformation = () => {
		const fields = [
			"locationAddress",
			"estimatedSquareFeet",
			"areUtilitiesOn",
			"isDealUnderContract",
			"isPropertyOccupied",
		]

		const isAnyFieldEmpty = fields.some(
			(field) =>
				formData[field] === null ||
				(Array.isArray(formData[field]) && formData[field].length === 0)
		)
		if (
			isAnyFieldEmpty ||
			(formData.isDealUnderContract === true &&
				(!formData.inspectionPeriod || !formData.closingDate))
		) {
			alert("Please fill in all fields before proceeding.")
			setFormError(true)
		} else {
			setFormError(false)
			nextStep()
		}
	}

	const verifyJobRequestService = async () => {
		const numberOfVendors =
			formData.vendorIds.length +
			formData.vendorInviteEmails.length +
			formData.vendorInvitePhoneNumbers.length

		const dateTimeNotValid =
			dateTimes.date === null ||
			dateTimes.startTime === "" ||
			dateTimes.endTime === ""

		const incompleteDateTime =
			(dateTimes.date !== null &&
				(dateTimes.startTime === "" || dateTimes.endTime === "")) ||
			(dateTimes.startTime !== "" &&
				(dateTimes.date === null || dateTimes.endTime === "")) ||
			(dateTimes.endTime !== "" &&
				(dateTimes.date === null || dateTimes.startTime === ""))

		if (
			formData.timePreference === null ||
			formData.serviceType.length === 0 ||
			(formData.servicePreferredDates.length === 0 && dateTimeNotValid) ||
			(formData.servicePreferredDates.length > 0 && incompleteDateTime) ||
			numberOfVendors === 0 ||
			numberOfVendors > 3
		) {
			alert("Please fill in all fields before proceeding.")
			setFormError(true)
			return false
		} else {
			setFormError(false)
			return true
		}
	}

	const handleClientCreation = async (userPlus, formData) => {
		const q1 = query(
			collection(db, "users"),
			where("email", "in", formData.clientEmailAddress)
		)
		const doc1 = await getDocs(q1)

		const userIds = doc1.docs.map((doc) => doc.data().id)
		const foundEmailAddresses = doc1.docs.map((doc) => doc.data().email)
		const notFoundEmailAddresses = formData.clientEmailAddress.filter(
			(email) => !foundEmailAddresses.includes(email)
		)

		const addClientPromises = userIds.map((userId) =>
			addAgentClient(
				userPlus.isAdmin && formData.teamMemberId
					? formData.teamMemberId.value
					: userPlus.id,
				userId
			)
		)

		const newClientIds = await Promise.all(
			addClientPromises.concat(
				notFoundEmailAddresses.map(async (email) => {
					const userId = await createUserAndAddAgentClient(
						userPlus.isAdmin && formData.teamMemberId
							? formData.teamMemberId.value
							: userPlus.id,
						email
					)
					return userId
				})
			)
		)

		return { newClientIds }
	}
	const addAgentClient = async (agentId, clientId) => {
		const q = query(
			collection(db, "agentClient"),
			where("clientId", "==", clientId),
			where("agentId", "==", agentId)
		)
		const docs = await getDocs(q)
		if (docs.docs.length === 0) {
			await addDoc(collection(db, "agentClient"), {
				agentId,
				clientId,
				teamId: userPlus.teamId ? userPlus.teamId : null,
			})
		}
		return clientId
	}

	const createUserAndAddAgentClient = async (agentId, email) => {
		const userDoc = await addDoc(collection(db, "users"), {
			id: crypto.randomUUID(),
			email: email.toLowerCase(),
			invitedBy: agentId,
			teamId: userPlus.teamId ? userPlus.teamId : null,
			userType: "client",
			jobRequestsMade: 0,
			_createdAt: new Date(),
		})

		await logUserEvent(analytic_events.INVITE_SENT, userPlus.id, {
			email: email,
			inviteType: "client",
			invitedBy: userPlus.id,
		})

		const newUser = await getDoc(doc(db, "users", userDoc.id))
		const userId = newUser.data().id

		await addAgentClient(agentId, userId)

		return userId
	}

	const handleVendorCreation = async (formData) => {
		const brandNewVendorIds = []
		const brandNewVendorEmails = []
		const registeredVendorEmails = []
		const registeredVendorIds = []
		const registeredNoPlanVendors = []
		const registeredDeactivatedVendors = []
		const vendorPhoneNumbers = []

		for (const inviteEmail of formData.vendorInviteEmails) {
			const {
				registeredVendorId,
				registeredVendorEmail,
				noPlanEmail,
				deactivatedEmail,
				newVendorEmail,
				newVendorId,
			} = await handleVendorInvitation(userPlus, inviteEmail)
			noPlanEmail && registeredNoPlanVendors.push(noPlanEmail)
			registeredVendorEmail &&
				registeredVendorEmails.push(registeredVendorEmail)
			registeredVendorId && registeredVendorIds.push(registeredVendorId)
			newVendorId && brandNewVendorIds.push(newVendorId)
			newVendorEmail && brandNewVendorEmails.push(newVendorEmail)
			deactivatedEmail && registeredDeactivatedVendors.push(deactivatedEmail)
		}

		for (const vendorData of formData.vendorInvitePhoneNumbers) {
			const { vendorPhoneNumber, registeredVendorId, newVendorId } =
				await handleVendorPhoneInvitation(userPlus, vendorData)
			vendorPhoneNumber && vendorPhoneNumbers.push(vendorPhoneNumber)
			registeredVendorId && registeredVendorIds.push(registeredVendorId)
			newVendorId && brandNewVendorIds.push(newVendorId)
		}

		for (const vendorId of formData.vendorIds) {
			const {
				registeredVendorId,
				registeredVendorEmail,
				noPlanEmail,
				deactivatedEmail,
				vendorPhoneNumber,
			} = await handleVendorIds(userPlus, vendorId)

			noPlanEmail && registeredNoPlanVendors.push(noPlanEmail)
			registeredVendorEmail &&
				registeredVendorEmails.push(registeredVendorEmail)
			registeredVendorId && registeredVendorIds.push(registeredVendorId)
			deactivatedEmail && registeredDeactivatedVendors.push(deactivatedEmail)
			vendorPhoneNumber && vendorPhoneNumbers.push(vendorPhoneNumber)
		}
		return {
			brandNewVendorIds,
			brandNewVendorEmails,
			registeredVendorEmails,
			registeredVendorIds,
			registeredNoPlanVendors,
			registeredDeactivatedVendors,
			vendorPhoneNumbers,
		}
	}

	const handleVendorInvitation = async (userPlus, inviteEmail) => {
		const q1 = query(
			collection(db, "users"),
			where("email", "==", inviteEmail.toLowerCase())
		)
		const doc1 = await getDocs(q1)
		if (doc1.docs.length > 0) {
			let subscriptionStatus = null
			try {
				const subscriptionStatusResult = await axios.post(
					`${config.baseURL}/api/subscription-check`,
					{ userEmail: doc1.docs[0].data().email }
				)
				subscriptionStatus = subscriptionStatusResult.data
			} catch (error) {
				console.error("Error getting stripe status:", error)
			}

			const noPlanEmail =
				Object.keys(subscriptionStatus).length === 0
					? doc1.docs[0].data().email
					: null
			const deactivatedEmail =
				subscriptionStatus.subscriptionInfo?.active === false &&
				subscriptionStatus.subscriptionInfo?.name !== null
					? doc1.docs[0].data().email
					: null
			const registeredVendorId = doc1.docs[0].data().id
			const registeredVendorEmail =
				subscriptionStatus.subscriptionInfo?.active === true &&
				subscriptionStatus.subscriptionInfo?.name !== null
					? doc1.docs[0].data().email
					: null

			await addVendorIfNotExists(userPlus.id, registeredVendorId)
			return {
				registeredVendorId,
				registeredVendorEmail,
				noPlanEmail,
				deactivatedEmail,
			}
		} else {
			const { newVendorId, newVendorEmail } = await createUserAndAddVendor(
				userPlus.id,
				inviteEmail
			)
			return { newVendorId, newVendorEmail }
		}
	}

	const handleVendorPhoneInvitation = async (userPlus, vendorData) => {
		const q1 = query(
			collection(db, "users"),
			where("phoneNumber", "==", vendorData.phoneNumber)
		)
		const doc1 = await getDocs(q1)
		if (doc1.docs.length > 0) {
			const registeredVendorId = doc1.docs[0].data().id
			const vendorPhoneNumber = doc1.docs[0].data().phoneNumber
			await addVendorIfNotExists(userPlus.id, registeredVendorId)
			return {
				registeredVendorId,
				vendorPhoneNumber,
			}
		} else {
			const { newVendorId, vendorPhoneNumber } =
				await createPhoneUserAndAddVendor(userPlus.id, vendorData)
			return { newVendorId, vendorPhoneNumber }
		}
	}

	const handleVendorIds = async (userPlus, vendorId) => {
		const q1 = query(collection(db, "users"), where("id", "==", vendorId.value))
		const doc1 = await getDocs(q1)

		if (doc1.docs.length > 0) {
			let subscriptionStatus = null
			try {
				const subscriptionStatusResult = await axios.post(
					`${config.baseURL}/api/subscription-check`,
					{ userEmail: doc1.docs[0].data().email }
				)
				subscriptionStatus = subscriptionStatusResult.data
			} catch (error) {
				console.error("Error getting stripe status:", error)
			}

			const noPlanEmail =
				Object.keys(subscriptionStatus).length === 0
					? doc1.docs[0].data().email
					: null
			const deactivatedEmail =
				subscriptionStatus.subscriptionInfo?.active === false &&
				subscriptionStatus.subscriptionInfo?.name !== null
					? doc1.docs[0].data().email
					: null
			const registeredVendorId = doc1.docs[0].data().id

			const registeredVendorEmail =
				subscriptionStatus.subscriptionInfo?.active === true &&
				subscriptionStatus.subscriptionInfo?.name !== null
					? doc1.docs[0].data().email
					: null

			let vendorPhoneNumber
			if (doc1.docs[0].data().phoneNumber) {
				vendorPhoneNumber = doc1.docs[0].data().phoneNumber
			}
			return {
				registeredVendorId,
				registeredVendorEmail,
				noPlanEmail,
				deactivatedEmail,
				vendorPhoneNumber,
			}
		}
		return {
			registeredVendorId: null,
			registeredVendorEmail: null,
			noPlanEmail: null,
			deactivatedEmail: null,
			vendorPhoneNumber: null,
		}
	}

	const addVendorIfNotExists = async (agentId, vendorId) => {
		const q2 = query(
			collection(db, "agentVendor"),
			where("agentId", "==", agentId),
			where("vendorId", "==", vendorId)
		)

		const doc2 = await getDocs(q2)
		if (doc2.docs.length === 0) {
			await addDoc(collection(db, "agentVendor"), {
				id: crypto.randomUUID(),
				agentId,
				vendorId,
				...(userPlus.isAdmin && { teamId: userPlus.teamId }),
			})
		}
	}

	const createUserAndAddVendor = async (agentId, email) => {
		const userDoc = await addDoc(collection(db, "users"), {
			id: crypto.randomUUID(),
			email: email.toLowerCase(),
			invitedBy: agentId,
			userType: "vendor",
			teamId: null,
			numberOfServices: 0,
			_createdAt: new Date(),
		})

		await logUserEvent(analytic_events.INVITE_SENT, userPlus.id, {
			email: email,
			inviteType: "vendor",
			invitedBy: userPlus.id,
		})

		const docRef = doc(db, "users", userDoc.id)
		const newUser = await getDoc(docRef)
		const newVendorId = newUser.data().id
		const newVendorEmail = newUser.data().email

		await addVendorIfNotExists(agentId, newVendorId)

		return { newVendorId, newVendorEmail }
	}

	const createPhoneUserAndAddVendor = async (agentId, vendorData) => {
		const userDoc = await addDoc(collection(db, "users"), {
			id: crypto.randomUUID(),
			phoneNumber: vendorData.phoneNumber,
			name: vendorData.companyName,
			invitedBy: agentId,
			userType: "vendor",
			teamId: null,
			numberOfServices: 0,
			_createdAt: new Date(),
		})

		await logUserEvent(analytic_events.INVITE_SENT, userPlus.id, {
			phoneNumber: vendorData.phoneNumber,
			inviteType: "vendor",
			invitedBy: userPlus.id,
		})

		const docRef = doc(db, "users", userDoc.id)
		const newUser = await getDoc(docRef)
		const newVendorId = newUser.data().id
		const vendorPhoneNumber = newUser.data().phoneNumber

		await addVendorIfNotExists(agentId, newVendorId)

		return { newVendorId, vendorPhoneNumber }
	}

	const onSubmit = async (e, overview) => {
		e.preventDefault()
		setSubmitting(true)
		if (!overview) {
			if ((await verifyJobRequestService()) === false) {
				setSubmitting(false)
				return "error"
			}
		}

		try {
			let agentData
			if (userPlus.userType === "client") {
				const q = query(
					collection(db, "users"),
					where("id", "==", userPlus.invitedBy)
				)
				const doc = await getDocs(q)
				agentData = doc.docs[0].data()
			}

			if (serviceRequests.length === 0) {
				const {
					newClientIds,
					brandNewVendorIds,
					brandNewVendorEmails,
					registeredVendorEmails,
					registeredVendorIds,
					registeredNoPlanVendors,
					registeredDeactivatedVendors,
					vendorPhoneNumbers,
				} = await handleJobRequestCreation(formData)

				const id = await addJobRequest(
					formData,
					brandNewVendorIds,
					registeredVendorIds,
					newClientIds
				)

				await incrementJobRequestsCount(1)

				await sendNotifications(
					{
						...formData,
						servicePreferredDates: [
							...formData.servicePreferredDates,
							...(dateTimes.date !== null &&
							dateTimes.startTime !== "" &&
							dateTimes.endTime !== ""
								? [dateTimes]
								: []),
						],
					},
					id,
					brandNewVendorEmails,
					registeredVendorEmails,
					registeredDeactivatedVendors,
					registeredNoPlanVendors,
					vendorPhoneNumbers,
					agentData
				)
			} else {
				for (const serviceRequest of serviceRequests) {
					const {
						newClientIds,
						brandNewVendorIds,
						brandNewVendorEmails,
						registeredVendorEmails,
						registeredVendorIds,
						registeredNoPlanVendors,
						registeredDeactivatedVendors,
						vendorPhoneNumbers,
					} = await handleJobRequestCreation(serviceRequest)

					const id = await addJobRequest(
						serviceRequest,
						brandNewVendorIds,
						registeredVendorIds,
						newClientIds
					)

					await sendNotifications(
						serviceRequest,
						id,
						brandNewVendorEmails,
						registeredVendorEmails,
						registeredDeactivatedVendors,
						registeredNoPlanVendors,
						vendorPhoneNumbers,
						agentData
					)
				}

				await incrementJobRequestsCount(serviceRequests.length)
			}
			navigate("/job-requests")
			setSubmitting(false)
		} catch (error) {
			console.error("Error submitting job request:", error)
		}
	}

	const incrementJobRequestsCount = async (numberOfRequests) => {
		try {
			await updateDoc(userPlus.userDocRef, {
				jobRequestsMade: increment(numberOfRequests),
			})
		} catch (error) {
			console.error("Error incrementing job requests count:", error)
			throw error
		}
	}

	const handleJobRequestCreation = async (formData) => {
		let newClientIds = []

		if (formData.clientEmailAddress.length > 0) {
			;({ newClientIds } = await handleClientCreation(userPlus, formData))
		}

		const {
			brandNewVendorIds,
			brandNewVendorEmails,
			registeredVendorEmails,
			registeredVendorIds,
			registeredNoPlanVendors,
			registeredDeactivatedVendors,
			vendorPhoneNumbers,
		} = await handleVendorCreation(formData)

		return {
			newClientIds,
			brandNewVendorIds,
			brandNewVendorEmails,
			registeredVendorEmails,
			registeredVendorIds,
			registeredNoPlanVendors,
			registeredDeactivatedVendors,
			vendorPhoneNumbers,
		}
	}

	const addJobRequest = async (
		formData,
		brandNewVendorIds,
		registeredVendorIds,
		newClientIds
	) => {
		const clientEmails = clientList
			.filter((client) => formData.clientIds.includes(client.value))
			.map((client) => client.label)

		const id = crypto.randomUUID()
		const jobRequestData = {
			id,
			userId: formData.teamMemberId ? formData.teamMemberId.value : userPlus.id,
			userEmail: formData.teamMemberId
				? formData.teamMemberId.label
				: userPlus.email,
			_createdAt: new Date(),
			_updatedAt: new Date(),
			_createdBy: userPlus.id,
			_updatedBy: userPlus.id,
			teamId:
				userPlus.teamId &&
				(userPlus.userType === "agent" || userPlus.userType === "TC")
					? userPlus.teamId
					: null,
			...formData,
			servicePreferredDates: [
				...formData.servicePreferredDates,
				...(dateTimes.date !== null &&
				dateTimes.startTime !== "" &&
				dateTimes.endTime !== ""
					? [dateTimes]
					: []),
			],
			vendorIds: [...(brandNewVendorIds || []), ...(registeredVendorIds || [])],
			clientIds: [
				...(newClientIds || []),
				...(formData.clientIds || []),
				...(userPlus.userType === "client" ? [userPlus.id] : []),
			],
			tcEmail: userPlus.userType === "TC" ? userPlus.email : null,
			clientEmailAddress: [
				...(clientEmails || []),
				...(formData.clientEmailAddress || []),
			],
		}

		if (userPlus.userType === "client") {
			jobRequestData.agentId = userPlus.invitedBy
		}

		await addDoc(collection(db, "jobRequests"), jobRequestData)
		return id
	}

	const sendNotifications = async (
		formData,
		id,
		brandNewVendorEmails,
		registeredVendorEmails,
		registeredDeactivatedVendors,
		registeredNoPlanVendors,
		vendorPhoneNumbers,
		agentData
	) => {
		const vendorsWithService = []
		const vendorsWithServiceIds = []
		const vendorsWithoutService = []

		for (const vendorEmail of registeredVendorEmails) {
			const q = query(
				collection(db, "users"),
				where("email", "==", vendorEmail)
			)
			const doc = await getDocs(q)
			const userId = doc.docs[0].data().id
			const docRef = query(
				collection(db, "services"),
				where("userId", "==", userId),
				where("serviceType", "==", formData.serviceType)
			)
			const docData = await getDocs(docRef)
			const ccEmails = docData.docs[0].data().ccEmails
				? docData.docs[0].data().ccEmails
				: null
			if (docData.docs.length === 0) {
				vendorsWithoutService.push(vendorEmail)
			} else {
				ccEmails
					? vendorsWithService.push([vendorEmail, ...ccEmails])
					: vendorsWithService.push(vendorEmail)
				vendorsWithServiceIds.push(userId)
			}
		}

		if (userPlus.userType === "agent" || userPlus.userType === "TC") {
			await sendJobRequestRegisteredVendorFromAgent(
				formData,
				vendorsWithService,
				userPlus.email,
				id
			)
		} else if (userPlus.userType === "client") {
			await sendJobRequestRegisteredVendorFromClient(
				formData,
				vendorsWithService,
				userPlus.email,
				id
			)

			const vendorNames = formData.vendorIds
				.map((vendorId) => vendorId.label)
				.join(", ")
			await sendAgentJobRequestNotification(
				formData,
				userPlus.email,
				userPlus.name,
				agentData,
				vendorNames
			)
		}

		if (brandNewVendorEmails.length > 0) {
			await sendJobRequestUnregisteredVendor(
				formData,
				brandNewVendorEmails,
				userPlus.name,
				userPlus.email
			)
		}

		if (registeredDeactivatedVendors.length > 0) {
			await sendJobRequestUnregisteredVendor(registeredDeactivatedVendors)
		}

		if (vendorsWithoutService.length > 0) {
			sendJobRequestVendorWithoutService(formData, id, vendorsWithoutService)
		}

		if (registeredNoPlanVendors.length > 0) {
			await sendJobRequestNonSubscribor(registeredNoPlanVendors)
		}

		if (vendorPhoneNumbers.length > 0) {
			await sendJobRequestSms(formData, id, vendorPhoneNumbers)
		}
	}

	const sendJobRequestSms = async (formData, id, vendorPhoneNumbers) => {
		vendorPhoneNumbers.forEach(async (vendorPhoneNumber, index) => {
			const serviceType = formData.serviceType
			try {
				await axios.post(`${config.baseURL}/api/sms-job-request`, {
					serviceType,
					id,
					vendorPhoneNumber,
				})
			} catch (error) {
				console.error("Error sending sms:", error)
			}
		})
	}

	const sendAgentJobRequestNotification = async (
		formData,
		clientEmail,
		clientName,
		agentData,
		vendorNames
	) => {
		try {
			await axios.post(
				`${config.baseURL}/api/job-request-email-agent-notification`,
				{
					formData,
					clientEmail,
					clientName,
					agentData,
					vendorNames,
				}
			)
		} catch (error) {
			console.error("Error sending email:", error)
		}
	}

	const sendJobRequestRegisteredVendorFromAgent = async (
		formData,
		registeredVendorEmails,
		userEmail,
		docId
	) => {
		registeredVendorEmails.forEach(async (vendorOwnerEmail, index) => {
			try {
				await axios.post(`${config.baseURL}/api/job-request-email-vendor`, {
					vendorOwnerEmail,
					agentEmail,
					userEmail,
					formData,
					docId,
				})
			} catch (error) {
				console.error("Error sending email:", error)
			}
		})
	}

	const sendJobRequestRegisteredVendorFromClient = async (
		formData,
		registeredVendorEmails,
		userEmail,
		docId
	) => {
		registeredVendorEmails.forEach(async (vendorOwnerEmail, index) => {
			try {
				await axios.post(`${config.baseURL}/api/job-request-email-client`, {
					vendorOwnerEmail,
					agentEmail,
					userEmail,
					formData,
					docId,
				})
			} catch (error) {
				console.error("Error sending email:", error)
			}
		})
	}

	const sendJobRequestUnregisteredVendor = async (
		serviceRequest,
		brandNewVendorEmails,
		userName,
		userEmail
	) => {
		brandNewVendorEmails.forEach(async (vendorEmail) => {
			try {
				await axios.post(
					`${config.baseURL}/api/job-request-email-unregistered`,
					{
						vendorEmail,
						userEmail,
						userName,
					}
				)
			} catch (error) {
				console.error("Error sending email:", error)
			}
		})
	}

	const sendJobRequestVendorWithoutService = async (
		serviceRequest,
		id,
		registeredVendorWithoutServiceEmails
	) => {
		registeredVendorWithoutServiceEmails.forEach(async (vendorEmail) => {
			try {
				await axios.post(
					`${config.baseURL}/api/job-request-email-vendor-without-service`,
					{
						vendorEmail,
						id,
						serviceRequest,
					}
				)
			} catch (error) {
				console.error("Error sending email:", error)
			}
		})
	}

	const sendJobRequestNonSubscribor = async (registeredDeactivatedVendors) => {
		registeredDeactivatedVendors.forEach(async (vendorEmail) => {
			try {
				await axios.post(`${config.baseURL}/api/send-job-request-not-active`, {
					vendorEmail,
				})
			} catch (error) {
				console.error("Error sending email:", error)
			}
		})
	}

	if (submitting) {
		return (
			<Center mt="50px">
				<Stack>
					<Spinner m="auto" />
					<Heading size="lg" fontWeight="bold" mt="30px">
						{" "}
						Sending job request{" "}
					</Heading>
				</Stack>
			</Center>
		)
	}

	switch (step) {
		case 1:
			return (
				<>
					<Box
						ml={{ base: "15px", lg: "93px" }}
						mr={{ base: "15px", md: "0px" }}
						mt="30px"
					>
						<Heading size="lg" fontWeight="bold">
							Create Job Request
						</Heading>
						<Text>
							Easily send all your job requests to track vendor estimates and
							availability in one place.{" "}
						</Text>
						<HStack my="15px" gap={0}>
							<IconButton
								aria-label="Return to Vendors"
								size="md"
								bgColor="background"
								icon={<ArrowBackIcon />}
								onClick={() => navigate("/vendors")}
								_hover={{ color: "red.500" }}
							/>
							<Link as={NavLink} variant="secondary" to="/vendors">
								Vendors
							</Link>
						</HStack>
					</Box>
					<JobRequestGeneralInformation
						nextStep={nextStep}
						handleChange={handleChange}
						handleGenericSelectChange={handleGenericSelectChange}
						verifyJobRequestGeneralInformation={
							verifyJobRequestGeneralInformation
						}
						formData={formData}
						formError={formError}
						setFormData={setFormData}
						clientList={clientList}
						setClientList={setClientList}
					/>
				</>
			)
		case 2:
			return (
				<>
					<Box
						ml={{ base: "15px", lg: "93px" }}
						mr={{ base: "15px", md: "0px" }}
						mt="30px"
					>
						<Heading size="lg" fontWeight="bold">
							Create Job Request
						</Heading>
						<Text>
							Quiplo creates and sends the job request to your selected vendors
							to help coordinate all your scheduling in one place.{" "}
						</Text>
						<HStack my="15px" gap={0}>
							<IconButton
								aria-label="Return to Vendors"
								size="md"
								bgColor="background"
								icon={<ArrowBackIcon />}
								onClick={() => navigate("/vendors")}
								_hover={{ color: "red.500" }}
							/>
							<Link as={NavLink} variant="secondary" to="/vendors">
								Vendors
							</Link>
						</HStack>
					</Box>
					<JobRequestServiceDetails
						nextStep={nextStep}
						previousStep={previousStep}
						handleChange={handleChange}
						handleServiceTimeRemoval={handleServiceTimeRemoval}
						dateTimes={dateTimes}
						setDateTimes={setDateTimes}
						handleVendorPhoneInviteRemoval={handleVendorPhoneInviteRemoval}
						handleGenericSelectChange={handleGenericSelectChange}
						editingIndex={editingIndex}
						serviceRequests={serviceRequests}
						formData={formData}
						formError={formError}
						onSubmit={onSubmit}
						handleAddServiceRequest={handleAddServiceRequest}
						setFormData={setFormData}
						setFormError={setFormError}
					/>
				</>
			)
		case 3:
			return (
				<>
					<Box
						ml={{ base: "15px", lg: "93px" }}
						mr={{ base: "15px", md: "0px" }}
						mt="30px"
					>
						<Heading size="lg" fontWeight="bold">
							Create Job Request
						</Heading>
						<Text>
							Quiplo creates and sends the job request to your selected vendors
							to help coordinate all your scheduling in one place.{" "}
						</Text>
						<HStack my="15px" gap={0}>
							<IconButton
								aria-label="Return to Vendors"
								size="md"
								bgColor="background"
								icon={<ArrowBackIcon />}
								onClick={() => navigate("/vendors")}
								_hover={{ color: "red.500" }}
							/>
							<Link as={NavLink} variant="secondary" to="/vendors">
								Vendors
							</Link>
						</HStack>
					</Box>
					<JobRequestOverview
						previousStep={previousStep}
						handleChange={handleChange}
						handleServiceRemoval={handleServiceRemoval}
						handleGenericSelectChange={handleGenericSelectChange}
						serviceRequests={serviceRequests}
						setFormData={setFormData}
						setEditingIndex={setEditingIndex}
						formData={formData}
						nextStep={nextStep}
						clientList={clientList}
						onSubmit={onSubmit}
					/>
				</>
			)
		default:
			return (
				<>
					<Box
						ml={{ base: "15px", lg: "93px" }}
						mr={{ base: "15px", md: "0px" }}
						mt="30px"
					>
						<Heading size="lg" fontWeight="bold">
							Create Job Request
						</Heading>
						<Text>
							Quiplo creates and sends the job request to your selected vendors
							to help coordinate all your scheduling in one place.{" "}
						</Text>
						<HStack my="15px" gap={0}>
							<IconButton
								aria-label="Return to Vendors"
								size="md"
								bgColor="background"
								icon={<ArrowBackIcon />}
								onClick={() => navigate("/vendors")}
								_hover={{ color: "red.500" }}
							/>
							<Link as={NavLink} variant="secondary" to="/vendors">
								Vendors
							</Link>
						</HStack>
					</Box>
					<JobRequestGeneralInformation
						nextStep={nextStep}
						handleChange={handleChange}
						verifyJobRequestGeneralInformation={
							verifyJobRequestGeneralInformation
						}
						handleGenericSelectChange={handleGenericSelectChange}
						formError={formError}
						formData={formData}
						setFormData={setFormData}
					/>
				</>
			)
	}
}
