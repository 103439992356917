import { useContext, useState, useEffect, useCallback, useMemo } from "react"
import { NavLink } from "react-router-dom"
import {
	Box,
	Button,
	Center,
	Image,
	Heading,
	Input,
	Stack,
	Text,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	useBreakpointValue,
	HStack,
	Tag,
} from "@chakra-ui/react"

import { collection, getDocs, query, where } from "firebase/firestore"
import { differenceInDays } from "date-fns"
import { db } from "../../firebase"
import { SkeletonTable } from "../../Components/SkeletonTable"
import { InsightListMobileCard } from "../../Components/InsightListMobileCard"
import { UserContext } from "../../Contexts/UserContext.js"
import { formatPhoneNumber, dateToFormat } from "../../Utilities"

export const InsightsList = () => {
	const { userPlus } = useContext(UserContext)
	const [hasMore, setHasMore] = useState(true)
	const [currentIndex, setCurrentIndex] = useState(0)
	const [allClients, setAllClients] = useState([])
	const [displayedClients, setDisplayedClients] = useState([])
	const [pageLoading, setPageLoading] = useState(true)
	const [searchTerm, setSearchTerm] = useState("")
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("")
	const [loginFilter, setLoginFilter] = useState("all")

	// Determine whether to show mobile or desktop view based on screen size
	const isMobile = useBreakpointValue({ base: true, md: false })
	const fontSize = useBreakpointValue({ base: "sm", md: "md" })

	const getClientsByTeamId = useCallback(async (teamId) => {
		const teamQuery = query(
			collection(db, "agentClient"),
			where("teamId", "==", teamId)
		)
		return await getDocs(teamQuery)
	}, [])

	const getClientsByAgentId = useCallback(async (agentId) => {
		const agentQuery = query(
			collection(db, "agentClient"),
			where("agentId", "==", agentId)
		)
		return await getDocs(agentQuery)
	}, [])

	useEffect(() => {
		if (userPlus === "pending" || userPlus === null || !userPlus?.userType)
			return

		const fetchData = async () => {
			try {
				setPageLoading(true)
				let clients = []

				if (userPlus.isAdmin) {
					clients = await getClientsByTeamId(userPlus.teamId)
				} else if (userPlus.userType === "agent" && !userPlus.isAdmin) {
					clients = await getClientsByAgentId(userPlus.id)
				}

				const clientUidList = clients.docs.map((doc) => doc.data().clientId)

				if (clientUidList.length > 0) {
					await fetchAllClients(clientUidList)
				} else {
					setAllClients([])
					setHasMore(false)
				}
			} catch (error) {
				console.error("Error fetching data:", error)
			} finally {
				setPageLoading(false)
			}
		}
		fetchData()
	}, [userPlus, getClientsByTeamId, getClientsByAgentId])

	const fetchAllClients = async (clientUidList) => {
		const allClientsFetched = []
		for (const clientId of clientUidList) {
			const clientQuery = query(
				collection(db, "users"),
				where("id", "==", clientId)
			)
			const doc = await getDocs(clientQuery)
			allClientsFetched.push(...doc.docs.map((doc) => doc.data()))
		}
		setAllClients(allClientsFetched)
		updateDisplayedClients(allClientsFetched, 0)
	}

	const updateDisplayedClients = (clients, index) => {
		const start = index * 10
		const end = start + 10
		setDisplayedClients(clients.slice(start, end))
		setHasMore(end < clients.length)
	}

	// Debounced search term
	useEffect(() => {
		const timerId = setTimeout(() => {
			setDebouncedSearchTerm(searchTerm)
		}, 300)
		return () => clearTimeout(timerId)
	}, [searchTerm])

	// Filter clients based on search term and login filter
	const filteredClients = useMemo(() => {
		let filtered = allClients.filter(
			(client) =>
				(client.name &&
					client.name
						.toLowerCase()
						.includes(debouncedSearchTerm.toLowerCase())) ||
				(client.email &&
					client.email
						.toLowerCase()
						.includes(debouncedSearchTerm.toLowerCase()))
		)

		if (loginFilter === "neverLoggedIn") {
			filtered = filtered.filter((client) => !client._lastLogin)
		} else if (loginFilter !== "all") {
			filtered = filtered.filter((client) => {
				const daysSinceLogin = differenceInDays(
					new Date(),
					client._lastLogin?.toDate()
				)
				return daysSinceLogin <= loginFilter
			})
		}

		return filtered
	}, [debouncedSearchTerm, allClients, loginFilter])

	useEffect(() => {
		updateDisplayedClients(filteredClients, 0)
		setCurrentIndex(0)
	}, [filteredClients])

	// Pagination handlers
	const goToNext = () => {
		const nextIndex = currentIndex + 1
		updateDisplayedClients(filteredClients, nextIndex)
		setCurrentIndex(nextIndex)
	}

	const goToPrevious = () => {
		const prevIndex = currentIndex - 1
		updateDisplayedClients(filteredClients, prevIndex)
		setCurrentIndex(prevIndex)
	}

	// Render last login tag
	const calculateLastLogin = (client) => {
		if (client._lastLogin) {
			const numberOfDays = differenceInDays(
				new Date(),
				client._lastLogin.toDate()
			)
			let color = "green"
			let tagText = `${numberOfDays} days ago`

			if (numberOfDays >= 30 && numberOfDays < 90) {
				color = "orange"
				tagText = `${numberOfDays} days ago`
			} else if (numberOfDays >= 90) {
				color = "red"
				tagText = `${numberOfDays} days ago`
			}

			return (
				<Tag colorScheme={color} variant="solid" borderRadius="full">
					{tagText}
				</Tag>
			)
		}
		return
	}

	return (
		<Box
			mt="30px"
			flexDirection="column"
			mr={{ base: "10px", lg: "30px", xl: "93px" }}
			ml={{ base: "10px", lg: "30px", xl: "93px" }}
		>
			<Heading size="lg" fontWeight="bold" mb="5px">
				Insights
			</Heading>
			<Stack
				spacing={2}
				alignItems={{ lg: "center" }}
				display={{ base: "block", md: "flex" }}
				direction={{ base: "column", md: "row" }}
				mb="20px"
			>
				<Input
					placeholder="Search clients"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					variant="jobRequest"
				/>

				{/* Login filter buttons */}

				<HStack
					spacing={isMobile ? 2 : 4}
					alignItems="center"
					mt={{ base: "10px", md: "0" }}
				>
					<Button
						onClick={() => setLoginFilter("all")}
						colorScheme={loginFilter === "all" ? "red" : "gray"}
						sx={{
							bg: loginFilter === "all" ? "red.500" : "gray.200",
							_hover: {
								bg: loginFilter === "all" ? "red.500" : "gray.200",
							},
						}}
						fontSize={fontSize}
					>
						All
					</Button>
					<Button
						onClick={() => setLoginFilter(30)}
						colorScheme={loginFilter === 30 ? "red" : "gray"}
						sx={{
							bg: loginFilter === 30 ? "red.500" : "gray.200",
							_hover: {
								bg: loginFilter === 30 ? "red.500" : "gray.200",
							},
						}}
						fontSize={fontSize}
					>
						{isMobile ? "1M" : "1 month"}
					</Button>
					<Button
						onClick={() => setLoginFilter(90)}
						colorScheme={loginFilter === 90 ? "red" : "gray"}
						sx={{
							bg: loginFilter === 90 ? "red.500" : "gray.200",
							_hover: {
								bg: loginFilter === 90 ? "red.500" : "gray.200",
							},
						}}
						fontSize={fontSize}
					>
						{isMobile ? "3M" : "3 months"}
					</Button>
					<Button
						onClick={() => setLoginFilter(180)}
						colorScheme={loginFilter === 180 ? "red" : "gray"}
						sx={{
							bg: loginFilter === 180 ? "red.500" : "gray.200",
							_hover: {
								bg: loginFilter === 180 ? "red.500" : "gray.200",
							},
						}}
						fontSize={fontSize}
					>
						{isMobile ? "6M" : "6 months"}
					</Button>
					<Button
						onClick={() => setLoginFilter(360)}
						colorScheme={loginFilter === 360 ? "red" : "gray"}
						sx={{
							bg: loginFilter === 360 ? "red.500" : "gray.200",
							_hover: {
								bg: loginFilter === 360 ? "red.500" : "gray.200",
							},
						}}
						fontSize={fontSize}
					>
						{isMobile ? "1Y" : "1 year"}
					</Button>
					<Button
						onClick={() => setLoginFilter("neverLoggedIn")}
						colorScheme={loginFilter === "neverLoggedIn" ? "red" : "gray"}
						sx={{
							bg: loginFilter === "neverLoggedIn" ? "red.500" : "gray.200",
							_hover: {
								bg: loginFilter === "neverLoggedIn" ? "red.500" : "gray.200",
							},
						}}
						fontSize={fontSize}
					>
						{isMobile ? "Not Yet" : "Not Yet Logged In"}
					</Button>
				</HStack>
			</Stack>

			{pageLoading && <SkeletonTable variant="insightList" />}

			{/* Desktop Table View */}
			{!isMobile && displayedClients.length > 0 && (
				<Table variant="simple" mt="20px">
					<Thead>
						<Tr>
							<Th>Last Login</Th>
							<Th>Client</Th>
							<Th>Invite Sent</Th>
							<Th>Job Requests</Th>
							<Th>Client Activity</Th>
						</Tr>
					</Thead>
					<Tbody>
						{displayedClients.map((client, index) => (
							<Tr key={client.id}>
								<Td>{calculateLastLogin(client)}</Td>
								<Td>
									<Text fontWeight="semibold">{client.name}</Text>
									<Text>
										{client.phoneNumber
											? formatPhoneNumber(client.phoneNumber)
											: client.email}
									</Text>
								</Td>
								<Td>{dateToFormat(client._createdAt)}</Td>
								<Td>{client.jobRequestsMade ? client.jobRequestsMade : 0}</Td>
								<Td>
									{client.jobRequestsMade > 0 && (
										<NavLink
											to={`/insights/${client.id}`}
											state={{ client }}
											style={{
												textDecoration: "underline",
												color: "#1A73E8",
											}}
										>
											View
										</NavLink>
									)}
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			)}

			{/* Mobile View */}
			{isMobile && displayedClients.length > 0 && (
				<Box>
					{displayedClients.map((client, index) => (
						<InsightListMobileCard
							client={client}
							clientTag={calculateLastLogin(client)}
							key={index}
						/>
					))}
				</Box>
			)}

			{displayedClients.length === 0 && !pageLoading && (
				<Center>
					<Stack spacing={3} alignItems="center">
						<Image
							src="../../assets/client_insights_empty_state.svg"
							w="200px"
						/>
						<Text>
							No insights to display at this time. Try adjusting your filters.
						</Text>
					</Stack>
				</Center>
			)}

			{displayedClients.length > 0 && (
				<Box
					display="flex"
					justifyContent="space-between"
					my="15px"
					mr={{ base: "10px", lg: "30px", xl: "93px" }}
				>
					<Button onClick={goToPrevious} isDisabled={currentIndex === 0}>
						Previous
					</Button>
					<Button onClick={goToNext} isDisabled={!hasMore}>
						Next
					</Button>
				</Box>
			)}
		</Box>
	)
}
